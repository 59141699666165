import React, { useState, useEffect } from "react";
import TrashQuestion from "./trashQuestion";
import Grid from "./grid";
import useWindowWidth from "./useWindowWidth";
import TypesOfQuestionsEnum from "./typesOfQuestionsEnum";
import Explanation from "./explanation";
import Tippy from "@tippyjs/react";
import { languageReadableMapLambda } from "./languageReadableMapLambda";
import Editor from "@monaco-editor/react";
import DarkThemeQuestion from "./darkThemeQuestion";
import ASingleTestEdit from "./aSingleTestEdit";
import AddTest from "./addTest";
import { useNavigate, useLocation } from "react-router-dom";
import RunNewTests from "./runNewTests";
import FullScreenToggler from "./fullScreenToggler";
import convertToNumber from "./convertToNumber";


const LambdaResponseEdit = ({ firstTime, status, allowedAttempts, results, attempt, useAutocompletion, constructor, isDarkTheme, updateIsDarkTheme, useSyntax, initAnswer, explanation, useExplanation, updateQuestions, questionsToSave, testsMap, showDrag, updateQuestionsToSave, provided, updateDeleteQuestion, index, points, question, uuid, questionType, language, useSyntaxHighlighting, bankUuid }) => {    
    const width = useWindowWidth(500);
    const navigate = useNavigate();
    const [initCall, setInitCall] = useState(false);
    const [initTests, setInitTests] = useState(null);
    const [fullscreen, setFullscreen] = useState(false);
    const location = useLocation();
    const [form, setForm] = useState({
        question: question,
        points: Number(points).toFixed(2),
        uuid: uuid,
        questionType: questionType,
        language: language,
        testsMap: testsMap,
        initAnswer: initAnswer,
        useExplanation: useExplanation,
        explanation: explanation,
        useSyntax: useSyntax,
        constructor: constructor,
        useAutocompletion: useAutocompletion,
        allowedAttempts: allowedAttempts
    });
    const [open, setOpen] = useState(false);

    function updateForm(key, value, firstCall=false) {
        setForm((prev) => {
            const objectToSave = {
                ...prev,
                [key]: value
            }            

            if (key === "testsMap" && !firstCall) {
                let newTotalPoints = 0;

                for (let key of Object.keys(value)) {
                    const possible = convertToNumber(value[key].passPoints);
        
                    if (Number.isFinite(possible)) {
                        newTotalPoints += possible;
                    }
                }

                objectToSave.points = newTotalPoints.toFixed(2).toString();
            }

            return objectToSave;
        })
    }

    const submitForm = async () => {
        // construct a new body for post with all values that have changed
        let body = {};
        body.fields = {}

        // check if we have something previous
        if (form.uuid in questionsToSave) {
            body.fields = questionsToSave[form.uuid].fields
        }

        body.uuid = uuid
        body.questionType = questionType        

        // Check each property for changes
        Object.keys(form).forEach(key => {
            if (form[key] !== { allowedAttempts, points, question, uuid, testsMap, questionType, language, initAnswer, useSyntax, useExplanation, useAutocompletion, explanation, constructor }[key]) {
                if (key !== "uuid" || key !== "questionType") {                
                    if (key === "points") {
                        if (form.points !== Number(points).toFixed(2)) {
                            body.fields[key] = form[key];
                        }
                    } else if (key === "testsMap" && initTests) {
                        if (form.testsMap !== initTests) {
                            body.fields[key] = form[key];
                        }
                    } else if (key !== "testsMap") {
                        body.fields[key] = form[key];
                    }
                    // pass if initTests is null
                }
            }
        });

        if (Object.keys(body.fields).length === 0) {
            return;
        }

        // updateQuestionsToSave
        updateQuestionsToSave((prev) => ({
            ...prev,
            [form.uuid]: body
        }))
    };

    useEffect(() => {
        // Define a timeout to submit the form after 0.5s
        const timer = setTimeout(() => {
            submitForm();
        }, 500); // 500ms delay
    
        // Cleanup function to clear the timeout if dependencies change
        return () => {
            clearTimeout(timer);
        };
    }, [form.points, form.question, form.explanation, form.initAnswer, form.constructor]);

    useEffect(() => {
        submitForm();
    }, [form.language, form.testsMap, form.useExplanation, form.useSyntax, form.useAutocompletion, form.allowedAttempts])

    useEffect(() => {
        updateQuestions((prev) => 
            prev.map((question) => {
                if (question.questionType === TypesOfQuestionsEnum.BANK_OF_QUESTIONS) {
                    return {
                        ...question,
                        questions: question.questions.map((nestedQuestion) => {
                            if (nestedQuestion.uuid === form.uuid) {
                                return form;
                            }
                            return nestedQuestion;
                        }),
                    };
                } else if (question.uuid === form.uuid) {
                    return {
                        ...form,
                        results,
                        attempt,
                        status
                    };
                }
                return question;
            })
        );
    }, [form]);

    useEffect(() => {
        if (typeof testsMap === 'object' && testsMap !== null && !initCall) {
            const newObject = Object.keys(testsMap).reduce((acc, key) => {
                
                acc[key] = {
                    ...testsMap[key], 
                    failPoints: Number(testsMap[key].failPoints).toFixed(2),
                    passPoints: Number(testsMap[key].passPoints).toFixed(2)
                }

                return acc;
            }, {})
    
            updateForm("testsMap", newObject, true)
            setInitCall(true)
            setInitTests(newObject)
        }
        
    }, [testsMap, initCall])

    const handleClick = (path, event) => {
        event.stopPropagation();
        
        if (event.metaKey || event.ctrlKey) {
            // Open in a new tab
            window.open(`/${path}`, '_blank');
        } else {
            // Navigate in the same tab
            navigate(`/${path}`);
        }
    }

    const setNewParams = () => {
        const searchParams = new URLSearchParams(location.search);
        if (searchParams.get('uuid') === uuid) {
            searchParams.set("uuid", ""); 
        } else {
            searchParams.set("uuid", uuid);
        }
        
        navigate(`?${searchParams.toString()}`);
    }

    function updateFullscreen(fullscreen) {
        setFullscreen(fullscreen);
    }

    useEffect(() => {
        if (form?.language === "plaintext") {
            updateForm("allowedAttempts", 0);
        }

    }, [form.language])

    const currentId = new URLSearchParams(location.search).get('uuid');

    useEffect(() => {
        if (firstTime) {
            setOpen(firstTime);
        }

    }, [firstTime])
    
    return (
        <>
            <div className="true-false-question-view" id={uuid}>
                <div className="question-number">
                    <div className="grid-number-container">
                        {
                            showDrag

                            ?

                            <>
                                <Grid provided={provided}/>
                            </>

                            :

                            <>
                            </>

                        }
                        Q{ index }
                        <Tippy content={uuid === currentId ? "Deselect Question" : "Select Question"} placement="right">
                            <input type={"checkbox"} className="check-auto" checked={uuid === currentId} onChange={() => {setNewParams()}} style={{ cursor: "pointer" }}/>
                        </Tippy>
                        <div onClick={(e) => handleClick("lambda-docs", e)}>
                            <Tippy content={"Click to navigate to lambda docs (max size ≈256KB)"} placement={"top"} theme="custom-tooltip">
                                <div className="docs-wrapper-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" viewBox="0 0 16 16">
                                        <path stroke="currentColor" strokeWidth=".3" d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
                                    </svg>
                                </div>
                            </Tippy>
                        </div>
                    </div>
                    <div className="pts">
                        <>
                            <input className="grading-box-question" type="none" value={form.points} onChange={(e) => {
                                updateForm("points", e.target.value);
                            }}/>
                            pts
                        </>
                        <TrashQuestion uuid={uuid} updateDeleteQuestion={updateDeleteQuestion} bankUuid={bankUuid}/>
                    </div>
                </div>
                <div className="text-response-area">
                    <textarea className="text-response-area-input" placeholder="Enter question (render Markdown and LaTeX)" style={{ minHeight: "100px" }} value={form.question} onChange={(e) => {
                        updateForm("question", e.target.value);
                    }}/>
                </div>
                <div className="text-response-area" style={{ marginBottom: "7px" }}>
                    <div className={`code-response-area ${fullscreen ? "fullscreen-editor-side-bar" : ""}`} style={{ backgroundColor: isDarkTheme ? "var(--vs-background)" : "white", color: isDarkTheme ? "white" : "var(--almost-black)" }}>
                        <div className="code-editor-header">
                            <FullScreenToggler updateFullscreen={updateFullscreen} fullscreen={fullscreen}/>
                            <DarkThemeQuestion isDarkTheme={isDarkTheme} updateIsDarkTheme={updateIsDarkTheme}/>
                        </div>                        
                        <Editor
                            language={form.language ? form.language : "plaintext"}
                            value={form.initAnswer}
                            theme={ isDarkTheme ? "vs-dark" : "vs"}
                            onChange={(value) => updateForm("initAnswer", value)}
                            options={{
                                fontFamily: "Consolas, 'Courier New', monospace", // Default font family
                                fontSize: 14, // Default font size (14px)
                                tabSize: 8,         // Set tab length to 4 spaces
                                fontWeight: "500", // Default font weight
                                suggestOnTriggerCharacters: true,
                                quickSuggestions: true                          
                            }}
                        />
                        <div className="code-editor-header" />
                    </div>
                </div>                
                <div className="container-zip-location">
                    <div className="message-container" style={{ flexDirection: width ? "column" : "", width: "100%"  }}>
                        <div className="message-container" style={{ alignItems: "center" }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" style={{ minWidth: "16px" }}>
                                <path d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2M6.646 5.646a.5.5 0 1 1 .708.708L5.707 8l1.647 1.646a.5.5 0 0 1-.708.708l-2-2a.5.5 0 0 1 0-.708zm2.708 0 2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L10.293 8 8.646 6.354a.5.5 0 1 1 .708-.708"/>
                            </svg>
                            Programming Language:
                        </div>
                        <select className="metric-select question" value={form.language} onChange={(e) => {
                            updateForm("language", e.target.value)
                        }} style={{ width: "auto", maxWidth: "150px" }}>                            
                            {Object.entries(languageReadableMapLambda).map(([key, value]) => (
                                <option key={key} value={value}>
                                    { key }
                                </option>
                            ))}
                        </select>
                    </div>                       
                </div>
                <div className="container-zip-location">
                    <div className="message-container" style={{ flexDirection: width ? "column" : "", width: "100%"  }}>
                        <div className="message-container" style={{ alignItems: "center" }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" style={{ minWidth: "16px" }}>
                                <path d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2M8.5 6v1.5H10a.5.5 0 0 1 0 1H8.5V10a.5.5 0 0 1-1 0V8.5H6a.5.5 0 0 1 0-1h1.5V6a.5.5 0 0 1 1 0"/>
                            </svg>                           
                            {
                                useSyntaxHighlighting && form.language !== "plaintext" ?

                                <>  
                                    Code Syntax:
                                    <input type={"checkbox"} className="check-auto" checked={form.useSyntax} onChange={(e) => updateForm("useSyntax", e.target.checked)}/>
                                </>

                                :

                                <>
                                    {
                                        form.language === "plaintext" ? "Choose a different language to configure code syntax" : "Turn on syntax highlighting to configure question-level syntax"
                                    }                                    
                                </>
                            }                                    
                        </div>
                    </div>                                                             
                </div>
                <div className="container-zip-location">
                    <div className="message-container" style={{ flexDirection: width ? "column" : "", alignItems: width ? "flex-start" : "center" }}>
                        <div className="message-container" style={{ alignItems: "center" }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" style={{ minWidth: "16px" }}>
                                <path d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2m-1.146 6.854-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 8.793l2.646-2.647a.5.5 0 0 1 .708.708"/>
                            </svg>                                    
                            {
                                useSyntaxHighlighting && form.language !== "plaintext" ?

                                <>  
                                    Code Autocompletion:
                                    <input className="check-auto" type="checkbox" checked={form.useAutocompletion} onChange={(e) => updateForm("useAutocompletion", e.target.checked)} />
                                </>

                                :

                                <>
                                    {
                                        form.language === "plaintext" ? "Choose a different language to configure code autocompletion" : "Turn on syntax highlighting to configure code completion"
                                    }                                    
                                </>
                            }
                        </div>                        
                    </div>                    
                </div>     
                <div className="container-zip-location">
                    <div className="message-container" style={{ flexDirection: width ? "column" : "", width: "100%"  }}>
                        <div className="message-container" style={{ alignItems: "center", textWrap: "nowrap" }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" style={{ minWidth: "16px" }}>
                                <path d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2M8.5 6v1.5H10a.5.5 0 0 1 0 1H8.5V10a.5.5 0 0 1-1 0V8.5H6a.5.5 0 0 1 0-1h1.5V6a.5.5 0 0 1 1 0"/>
                            </svg>       
                            {
                                form.language !== "plaintext" ?

                                <div className="codes-container" style={{ justifyContent: "flex-start", columnGap: "10px" }}>
                                Additional Attempts:
                                {
                                    allowedAttempts !== -1
                                    
                                    ?

                                    <input type="number" className="grade-box-enter entry-exit" value={form.allowedAttempts} min={0} onChange={(e) => {
                                        updateForm("allowedAttempts", e.target.value);
                                    }}/>

                                    :
                                    
                                    <>
                                    </>
                                }
                                <input type="checkbox" checked={allowedAttempts === -1} onChange={() => {
                                    if (allowedAttempts === -1) {
                                        updateForm("allowedAttempts", 0);  
                                    } else {
                                        updateForm("allowedAttempts", -1);  
                                    }                                                           
                                }}/>
                                Unlimited                                                                    
                            </div>

                            :

                            <>
                                Choose a different language to configure additional attempts
                            </>
                            
                            }                                                
                        </div>
                    </div>                                                             
                </div>                                           
                <div className="container-zip-location">
                    <div className="message-container" style={{ flexDirection: width ? "column" : "", width: "100%"  }}>
                        <div className="message-container" style={{ alignItems: "center" }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" style={{ minWidth: "16px" }}>
                                <path d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2M6 5.883a.5.5 0 0 1 .757-.429l3.528 2.117a.5.5 0 0 1 0 .858l-3.528 2.117a.5.5 0 0 1-.757-.43V5.884z"/>
                            </svg>                     
                            {
                                form.language && form.language !== "plaintext" ? (
                                    <div className="metric-select question-2" onClick={() => {
                                        setOpen(prev => !prev);                                      
                                    }}>
                                        Tests
                                        <div className="center-chevron">
                                            {
                                                open ?
            
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                                    <path d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"/>
                                                </svg>
            
                                                :
            
                                                
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                                    <path d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
                                                </svg>
            
                                            }
                                        </div>
                                    </div>                               
                                ) : "Choose a different language to configure unit tests"
                            }                                    
                        </div>
                    </div>
                </div>                  
                {
                    open && form.language && form.language !== "plaintext" && (
                        <>
                            <div className="container-zip-location">
                                <div className="tests-wrapper">
                                    {
                                        form.testsMap && Object.keys(form.testsMap).length > 0 && Object.keys(form.testsMap).map((uuid, index) => {
                                            const test = form.testsMap[uuid];
                                            return <ASingleTestEdit testName={test.testName} preface={test.preface} langauge={form.language} index={index} currentTest={test} key={uuid} uuid={uuid} expression={test.expression} passPoints={test.passPoints} failPoints={test.failPoints} testsMap={form.testsMap} updateForm={updateForm}/>
                                        })
                                    }
                                    <AddTest updateQuestion={updateForm} testsMap={form.testsMap}/>
                                </div>                                
                            </div>
                        </>
                    )
                } 
                {
                    open && (
                        <div className="select-all-that-apply-array">
                            <RunNewTests status={status} language={language} testsMap={form.testsMap} results={results} attempt={attempt} uuid={uuid}/>                 
                        </div>
                    )
                }        
                <div className="select-all-that-apply-array">
                    <Explanation form={form} updateForm={updateForm} submitForm={submitForm}/>
                </div>
            </div>
        </>
    );
}

export default LambdaResponseEdit;