import Tippy from "@tippyjs/react";
import React from "react"

const DarkThemeQuestion = ({ updateIsDarkTheme, isDarkTheme }) => {
    const handleClick = () => {
        updateIsDarkTheme(!isDarkTheme)
    }

    return (
        <>
            <Tippy placement="top" content="Switch Color Theme">
                <div className="zip-download-container" onClick={handleClick}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16M1 8a7 7 0 0 0 7 7 3.5 3.5 0 1 0 0-7 3.5 3.5 0 1 1 0-7 7 7 0 0 0-7 7"/>
                    </svg>
                </div>
            </Tippy>
        </>
    );
}

export default DarkThemeQuestion;