import React, { useState, useEffect } from "react";
import WhiteLoading from "./whiteLoading";
import TypesTestsEnum from "./typesTestsEnum";
import UploadAndViewTests from "./uploadAndViewTests";
import findTestsFromQsAndBankedQs from "./findTestsFromQsAndBankedQs";
import { useNavigate, useLocation } from "react-router-dom";
import TestsDropdown from "./testsDropdown";
import TopRightTestViewer from "./topRightTestViewer";

const TestsViewer = ({ updateStatus, status, updateAttempt, attempt, autograderRequestLifeCycles, updateAutograderRequestLifeCycles, updateError, error, updateTestsConfigDictForUpload, testsConfigDictForUpload, currentViewerForTesting, updateCurrentViewerForTesting, questionsForUpload, updateQuestionsForUpload, testsConfigDict, requestLifeCycle, questionsFromParent, updateCurrentViewer }) => {
    const [questions, setQuestions] = useState(findTestsFromQsAndBankedQs(questionsFromParent ? questionsFromParent : []))
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        setQuestions(findTestsFromQsAndBankedQs(questionsFromParent ? questionsFromParent : []))
    }, [questionsFromParent])

    const handleButtonClick = (type) => {
        // Existing query parameters
        const searchParams = new URLSearchParams(location.search);

        // Add or modify a query parameter
        searchParams.set('test', type); // Adds or updates 'newParam'

        // Build the new query string
        const newSearch = searchParams.toString();

        // Navigate to the same path with the updated query params
        navigate({
            pathname: location.pathname,
            search: newSearch,
        });
    }

    return (
        <>
            <div className="tests-container-parent">
                <>
                    {
                        requestLifeCycle ?
                    
                        <>
                            <div className="loading-zip-container">
                                <WhiteLoading />
                            </div>                       
                        </>

                        :

                        <>
                            {
                                currentViewerForTesting === TypesTestsEnum.VIEW

                                ?

                                <>
                                    <TestsDropdown questions={questions} testsConfigDict={testsConfigDict} updateCurrentViewer={updateCurrentViewer}/>
                                </>

                                :

                                <>
                                    <UploadAndViewTests updateStatus={updateStatus} status={status} requestLifeCycle={requestLifeCycle} updateAttempt={updateAttempt} attempt={attempt} autograderRequestLifeCycles={autograderRequestLifeCycles} updateAutograderRequestLifeCycles={updateAutograderRequestLifeCycles} error={error} updateError={updateError} updateTestsConfigDictForUpload={updateTestsConfigDictForUpload} testsConfigDictForUpload={testsConfigDictForUpload} questionsForUpload={questionsForUpload} updateQuestionsForUpload={updateQuestionsForUpload} initQuestions={questions} updateCurrentViewer={updateCurrentViewer}/>
                                </>
                                
                            }
                        </>                    
                    }
                </>
                <TopRightTestViewer handleButtonClick={handleButtonClick} updateCurrentViewerForTesting={updateCurrentViewerForTesting} currentViewerForTesting={currentViewerForTesting}/>
            </div>            
        </>
    );
}

export default TestsViewer;