import React from "react";
import Server from "./server";
import Roster from "./roster";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import Tippy from "@tippyjs/react";

const NavInfoCourseCollapsed = () => {
    const navigate = useNavigate();
    const { courseCode } = useParams();
    const location = useLocation();
    const currentPath = location.pathname;

    // Check if the URL ends with '/roster'
    const isRoster = currentPath.endsWith('/roster');
    
    const handleClick = (path, event) => {
        if (event.metaKey || event.ctrlKey) {
            // Open in a new tab
            window.open(`/${courseCode}/${path}`, '_blank');
        } else {
            // Navigate in the same tab
        navigate(`/${courseCode}/${path}`);
        }
    }

    return (
        <>
            <div className="collapsed-container" style={{ marginTop: "164px" }}>
                <Tippy content={"Dashboard"} placement={"right"}>
                    <div className={`collapsed ${!isRoster ? "current" : "hover"}`} style={{ cursor: "pointer" }} onClick={ (e) => {handleClick("", e);} }>
                        <Server />
                    </div>
                </Tippy>
                <Tippy content={"Roster"} placement={"right"}>                
                    <div className={`collapsed ${isRoster ? "current" : "hover"}`} style={{ cursor: "pointer" }} onClick={ (e) => {handleClick("roster", e);} }>
                        <Roster />
                    </div>
                </Tippy>
            </div>
        </>
    );
}

export default NavInfoCourseCollapsed;