import React, { useState, useEffect } from "react";
import GradingBoxQuestion from "./gradingBoxQuestion";
import MarkdownWithLaTeX from "./markDownWithLatex";
import PDFIcon from "./pdfIcon";
import Loading from "./loading";
import { useParams } from "react-router-dom";
import TrashOption from "./trashOption";
import uploadFile from "./uploadFileToS3";
import Tippy from "@tippyjs/react";

const CompleteOnPaperView = ({ id, updateId, isDarkTheme, updatePdfUrls, pdfUrls, questionNumber, question, points, grading, pointsEarned, uuid }) => {
    const [pdfToUpload, setPdfToUpload] = useState("");
    const [requestLifeCycle, setRequestLifeCycle] = useState(false);
    const [error, setError] = useState(false);
    const { courseCode, assessmentCode, email } = useParams();

    useEffect(() => {
        const updateQuestionPdf = async () => {
            if (requestLifeCycle) {
                return;
            }
            
            // form validation
            const maxSizeInBytes = 50 * 1024 * 1024; // 50MB

            if (pdfToUpload.size > maxSizeInBytes) {
                setError(true)
                return;
            }

            try {
                setRequestLifeCycle(true);

                // create form data
                const formData = new FormData();
                formData.append("courseCode", courseCode)
                formData.append("assessmentCode", assessmentCode)
                formData.append("email", email)
                formData.append("uuid", uuid)

                const url = process.env.REACT_APP_SUBMISSION_API_URL + "/update-question-pdf"

                const urlOptions = {
                    method: "POST",
                    credentials: "include",
                    body: formData
                }

                const response = await fetch(url, urlOptions);
                const data = await response.json();
                if (response.status === 200 && data.url) {
                    const status = await uploadFile(pdfToUpload, data.url, "application/pdf"); // Get the ZIP as a blob
                    
                    if (status !== 200) {
                        return;
                    }
                    
                    const pdfUrl = URL.createObjectURL(pdfToUpload);
                    updatePdfUrls((prev) => ({
                        ...prev,
                        [uuid]: pdfUrl, // Storing the PDF content or URL here
                    }));

                } else if (response.status === 401) {
                    window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
                }
            } catch (error) {
                console.log(error)
            } finally {
                setRequestLifeCycle(false);
            }
        }

        if (pdfToUpload) {
            updateQuestionPdf();
        }

    }, [pdfToUpload])

    const deleteUploadPdf = async () => {
        if (requestLifeCycle) {
            return;
        }
        
        try {
            setRequestLifeCycle(true);
            const url = process.env.REACT_APP_SUBMISSION_API_URL + "/delete-question-pdf"
            const urlOptions = {
                method: "POST",
                credentials: "include",
                headers: {
                    'Content-Type': "application/json"
                },
                body: JSON.stringify({
                    courseCode: courseCode,
                    assessmentCode: assessmentCode,
                    email: email,
                    uuid: uuid,
                })
            }

            const response = await fetch(url, urlOptions);
            if (response.status === 200) {
                const urlToDelete = pdfUrls[uuid];
                updatePdfUrls((prev) => ({
                    ...prev,
                    [uuid]: null
                }))

                URL.revokeObjectURL(urlToDelete)

            } else if (response.status === 401) {
                window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
            }
        } catch (error) {
            console.log(error)
        } finally {
            setRequestLifeCycle(false);
        }
    }

    useEffect(() => {
        if (error) {
            const timer = setTimeout(() => {
                setError(false);
            }, 2000);
    
            return () => {
                clearTimeout(timer); // Clear timeout to avoid memory leaks.
            };
        }
    }, [error]);

    const pdfExists = pdfUrls && uuid in pdfUrls && pdfUrls[uuid];

    return (
        <>
            <div className="true-false-question-view" id={uuid + "-preview"}>
                <div className="question-number">
                    <div className="pdf-tippy-plus-upload">
                        Q{ questionNumber }
                        {
                            grading && (
                                <Tippy content={uuid === id ? "Deselect Answer" : "Select Answer"} placement="right">
                                    <input type={"checkbox"} className="check-auto" checked={uuid === id} onChange={() => {updateId(uuid === id ? "" : uuid)}} style={{ cursor: "pointer" }}/>
                                </Tippy>
                            )
                        }
                        {
                            grading && (
                                <Tippy content={"PDF max size of 50MB"} placement={"top"} theme="custom-tooltip">
                                    <div className="docs-wrapper-icon" style={{ color: error ? "red" : "", border: error ? "1px solid red" : "" }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" viewBox="0 0 16 16">
                                            <path stroke="currentColor" strokeWidth=".3" d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
                                        </svg>
                                    </div>
                                </Tippy>
                            )
                        }
                    </div>
                    <div className="pts">
                        {
                            grading

                            ?

                            <>
                                <GradingBoxQuestion pointsEarned={pointsEarned} uuid={uuid}/>
                            </>

                            :

                            <>
                            </>
                        }
                        { (grading ? "/ " : "") + Number(points).toFixed(2) } pts
                    </div>
                </div>
                <div className="true-false-top">
                    <MarkdownWithLaTeX content={question} isDarkTheme={isDarkTheme}/>
                </div>
                {/* render the pdf that is uploaded and have an upload region */}
                {
                    grading 

                    ?

                    <>
                        {
                            requestLifeCycle || pdfUrls?.requestLifeCycle

                            ?

                            <div className="grading-pdf-question">
                                <Loading />
                            </div>

                            :

                            <>  
                                {/* file upload and delete trash can */}
                                <>
                                    <div className="upload-delete-pdf-question-container">
                                        <input type="file" accept="application/pdf" onChange={(e) => setPdfToUpload(e.target.files[0])}/>                                         
                                        {
                                            pdfExists

                                            ?
                                        
                                                <div onClick={deleteUploadPdf}>
                                                    <TrashOption />
                                                </div>

                                            :

                                            <>
                                            </>

                                        }
                                    </div>
                                </>
                                {
                                    !pdfExists
                
                                    ?
                
                                    <div className="grading-pdf-question">
                                        <PDFIcon />
                                    </div>
                
                                    :

                                    <div className="grading-pdf-question">
                                        <iframe
                                            src={pdfUrls[uuid]}
                                            style={{ width: "100%", height: "100%", margin: "5px" }}
                                        />
                                    </div>
                                }
                            </>
                        }                    
                    </>

                    :

                    <>
                    </>
                }
            </div>
        </>
    );
}

export default CompleteOnPaperView;