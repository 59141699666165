import React, { useState } from "react";
import CircleWithTextView from "./circleWithTextView";
import MarkdownWithLaTeX from "./markDownWithLatex";
import CircleWithTextViewGradingPage from "./circleWithTextViewGradingPage";
import GradingBoxQuestion from "./gradingBoxQuestion";
import Regrade from "./regrade";
import Tippy from "@tippyjs/react";

const MultipleChoiceView = ({ id, updateId, isDarkTheme, useCustomScore, questionNumber, question, points, options, grading, pointsEarned, studentAnswer, answerIndexAnswer, uuid }) => {
    const [answerIndex, setAnswerIndex] = useState(-1);
    const [useCustomScoreFromGrading, setUseCustomScoreFromGrading] = useState(useCustomScore);

    function updateUseCustomScoreFromGrading(bool) {
        setUseCustomScoreFromGrading(bool);
    }

    function updateIndex(newIndex) {
        if (newIndex === answerIndex) {
            setAnswerIndex(-1)
        } else {
            setAnswerIndex(newIndex);
        }
    }

    const handleStateCalculation = (answerState) => {
        // check if answers match or check if they match the possible answerState
        if (studentAnswer === answerIndexAnswer && (answerIndexAnswer === answerState)) {
            return 1;
        } 
        // check if the answers dont't match and the correct answer state is a match
        else if (studentAnswer !== answerIndexAnswer && (studentAnswer === answerState)) {
            return 0;
        }
        // if correct and not answered
        else if (studentAnswer !== answerIndexAnswer && (answerIndexAnswer === answerState)) {
            return 2;
        }
        // if both fail, we have a blank answer
        else {
            return -1;
        }
    }

    return (
        <>
            <div className="true-false-question-view" id={uuid + "-preview"}>
                <div className="question-number">
                    <div className="grid-number-container">
                        Q{ questionNumber }
                        {
                            grading && (
                                <Tippy content={uuid === id ? "Deselect Answer" : "Select Answer"} placement="right">
                                    <input type={"checkbox"} className="check-auto" checked={uuid === id} onChange={() => {updateId(uuid === id ? "" : uuid)}} style={{ cursor: "pointer" }}/>
                                </Tippy>
                            )
                        }
                    </div>
                    <div className="pts">  
                        {
                            useCustomScoreFromGrading && <Regrade updateUseCustomScoreFromGrading={updateUseCustomScoreFromGrading} uuid={uuid}/>
                        }                  
                        {
                            grading

                            ?

                            <>
                                <GradingBoxQuestion pointsEarned={pointsEarned} uuid={uuid} updateUseCustomScoreFromGrading={updateUseCustomScoreFromGrading}/>
                            </>

                            :

                            <>
                            </>
                        }
                        { (grading ? "/ " : "") + Number(points).toFixed(2) } pts
                    </div>
                </div>
                <div className="true-false-top">
                    <MarkdownWithLaTeX content={question} isDarkTheme={isDarkTheme}/>
                </div>
                <div className="select-all-that-apply-array">
                    {
                        options && options.length > 0 

                        ?

                        options.map((option) => (
                            
                            grading 

                            ?

                            <CircleWithTextViewGradingPage key={option.index} state={handleStateCalculation(option.index)} text={option.option}/>

                            :

                            <CircleWithTextView key={option.index} correct={answerIndex === option.index} index={option.index} text={option.option} updateIndex={updateIndex}/>
                        ))

                        :

                        <>
                        </>

                    }
                </div>
            </div>
        </>
    );
}

export default MultipleChoiceView;