import React, { useEffect, useState } from "react";
import CourseInput from "./courseInput";
import SaveChanges from "./saveChanges";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import WhiteLoading from "./whiteLoading";
import TypeQuestionSelect from "./typeQuestionSelect";
import Info from "./info";
import TypesOfQuestionsEnum from "./typesOfQuestionsEnum";
import createQuestion from "./createQuestion";

const AddAQuestionForm = ({ updateIsAllowedToScoll, updateQuestions, updateAddAQuestion, questions }) => {
    const [text, setText] = useState("Addition will not affect existing submissions");
    const [requestLifeCycle, setRequestLifeCycle] = useState(false);
    const { courseCode, assessmentCode } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [form, setForm] = useState({
        questionType: TypesOfQuestionsEnum.MULTIPLE_CHOICE,
        points: "1",
        courseCode: courseCode,
        assessmentCode: assessmentCode,
        question: "",
        questions: questions.map((question) => question.uuid)
    })

    function updatePoints(points) {
        if (requestLifeCycle) {
            return;
        }
        setForm(prevForm => ({
            ...prevForm,
            points: points,
        }));
    }

    function updateQuestionType(questionType, value) {
        if (requestLifeCycle) {
            return;
        }
        if (value === TypesOfQuestionsEnum.CONTEXT_BLOCK || value === TypesOfQuestionsEnum.BANK_OF_QUESTIONS ) {
            setForm(prevForm => ({
                ...prevForm,
                points: "0",
            }));
        }
        setForm(prevForm => ({
            ...prevForm,
            [questionType]: value,
        }));
    }

    const resetForm = () => {
        updateAddAQuestion(false);
    }

    const addQuestion = (uuid) => {
        const question = createQuestion(form, uuid);
    
        if (question === null) {
            setText("Question addition failed")
            return;
        }


        updateQuestions((prevQuestions) => {
            return [...prevQuestions, question]
        });

        updateAddAQuestion(false);
    }         
    
    const setNewParams = () => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set("uuid", "");
        navigate(`?${searchParams.toString()}`);
    }

    const submitForm = async (e) => {
        e.preventDefault();

        if (requestLifeCycle) {
            return;
        }

        // form validation
        for (const value in form) {
            if (form[value] === "" && value === "points") {
                setText("Please enter a valid point amount")
                return;
            }
        }

        const url = process.env.REACT_APP_EVALUATION_API_URL + "/add-question"

        const urlOptions = {    
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(form)
        }

        try {
            setRequestLifeCycle(true)
            const res = await fetch(url, urlOptions);
            const data = await res.json();
            if (res.status === 200 && data.uuid) {
                setText("Question added")
                setNewParams();
                updateAddAQuestion(false);
                addQuestion(data.uuid);
                updateIsAllowedToScoll(true);
            }
            else if (res.status === 401) {
                setText("Authentication error")
                window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
            } else {
                setText("An error occurred")
            }

        } catch (error) {
            setText("An error occurred")
            console.log(error);
        } finally {
            setRequestLifeCycle(false)
        }
        
    }

    useEffect(() => {
        setText("Addition will not affect existing submissions");
    }, [form])

    return (
        <>
            <div className="black-back" onClick={ () => {updateAddAQuestion(false)} } style={{ opacity: 0 }}>

            </div>
            <div className="add-a-course-form-container add-a-question">
                <div className="add-a-course-form-content-container">
                    <form className="form-width">
                        <div className="add-course-title">
                            Add A Question
                        </div>
                        <div className="account-settings-content">
                            <div className="required-container">
                                All Fields Required
                            </div>   
                            <div className="banner">
                                {
                                    requestLifeCycle 

                                    ?
                                    
                                    <>
                                        <WhiteLoading />
                                    </>

                                    :

                                    <div className="banner-info">
                                        <Info />
                                        { text }
                                    </div>

                                }
                            </div>
                            {
                                form.questionType !== TypesOfQuestionsEnum.BANK_OF_QUESTIONS && form.questionType !== TypesOfQuestionsEnum.CONTEXT_BLOCK ?

                                <>
                                     <div className="points-viewing-container">
                                        <div style={{ width: "45%" }}>
                                            <div className="input-field">
                                                <div className="top-input">
                                                Select Question Type
                                                </div>
                                                <TypeQuestionSelect updateQuestion={updateQuestionType} questionType={form.questionType}/>
                                                
                                            </div>                                        
                                        </div>
                                        <div style={{ width: "45%" }}>
                                            <CourseInput top={"Total Points"} value={form.points} updateValue={updatePoints} type={"number"} required={true} autoComplete={"off"} min={0}/>
                                        </div>
                                    </div>
                                </>

                                :

                                <>
                                     <div className="points-viewing-container">
                                        <div className="input-field">
                                            <div className="top-input">
                                            Select Question Type
                                            </div>
                                            <TypeQuestionSelect updateQuestion={updateQuestionType} questionType={form.questionType}/>
                                            
                                        </div>
                                    </div>
                                </>
                            
                            }                           
                            <SaveChanges left="Cancel" right={{ small : "Add A Question", large : "Add A Question" }} resetForm={resetForm} submitForm={submitForm}/> 
                        </div>
                    </form> 
                </div>
            </div>
        </>
    );
}

export default AddAQuestionForm;