import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loading from "./loading";
import Tippy from "@tippyjs/react";
import ASingleTestLambdaGrading from "./ASingleTestLambdaGrading";
import ReloadButton from "./reloadButton";

const RunNewTests = ({ status, attempt, testsMap, results, uuid, language }) => {
    const { courseCode, assessmentCode } = useParams();
    const [requestLifeCycle, setRequestLifeCycle] = useState(false);
    const [error, setError] = useState(null);
    const [componentStatus, setComponentStatus] = useState(null);
    const [componentAttempt, setComponentAttempt] = useState(null);
    const [componentResults, setComponenetResults] = useState(null);
 
    const handleClick = async () => {
        if (requestLifeCycle) {
            return;
        }

        try {
            setRequestLifeCycle(true);            
            const url = process.env.REACT_APP_EVALUATION_API_URL + `/test-question/${courseCode}/${assessmentCode}/${uuid}/${language}`
            const urlOptions = {
                method: "POST",
                credentials: "include",                
            }
            
            const response = await fetch(url, urlOptions);
            const data = await response.json();
            if (response.status === 200) {
                setComponentStatus('RUNNING');
                setComponentAttempt(prev => prev + 1);
            } else if (response.status === 401) {
                window.location.href = process.env.REACT_APP_401_REDIRECT_URL
            } else {
                setError(data.detail)
                setComponentStatus('COMPLETED');
            }

        } catch (error) {
            console.log(error);
            setError(error.message);
            setComponentStatus('COMPLETED');
        } finally {
            setRequestLifeCycle(false);
        }
    }

    useEffect(() => {
        if (status) {
            setComponentStatus(status);
        }
    }, [status])

    useEffect(() => {
        if (attempt) {
            setComponentAttempt(attempt);
        }
    }, [attempt])

    useEffect(() => {
        if (results) {
            setComponenetResults(results);
        }
    }, [results])

    const fetchResults = async () => {
        if (requestLifeCycle) {
            return;
        }

        try {
            setRequestLifeCycle(true);
            const url = process.env.REACT_APP_EVALUATION_API_URL + `/fetch-lambda-results/${courseCode}/${assessmentCode}/${uuid}`
            const urlOptions = {
                method: "GET",
                credentials: "include",                
            }
            
            const response = await fetch(url, urlOptions);
            const data = await response.json();
            if (response.status === 200) {
                setComponentStatus(data.status);
                setComponentAttempt(data.attempt);
                setComponenetResults(data.results);
                setError(null);
            } else if (response.status === 401) {
                window.location.href = process.env.REACT_APP_401_REDIRECT_URL
            } else {
                setError(data.detail);
                setComponentStatus('COMPLETED');
                setComponenetResults(null);
            }

        } catch (error) {
            console.log(error);
            setError(error.message);
            setComponentStatus('COMPLETED');
            setComponenetResults(null);
        } finally {
            setRequestLifeCycle(false);
        }
    }

    return (
        <>
            {
                requestLifeCycle ?

                <div className="loading-zip-container" style={{ height: "50px" }}>
                    <Loading />
                </div>

                :

                language && language !== "plaintext" && testsMap && typeof testsMap === "object" && Object.keys(testsMap).length > 0 ?
                    <div className="run-new">
                        <div className="tests-center" style={{ marginBottom: "10px", columnGap: "10px" }}>                            
                            <div onClick={fetchResults}>
                                <ReloadButton />
                            </div>
                            <div className="top-right-item run-tests" onClick={handleClick}>                                                            
                                Run Tests
                                <Tippy placement="top" content={<div style={{ fontSize: '12px', textAlign: "center" }}>Run tests on saved question configuration. Reload periodically for results.</div>} theme="custom-tooltip">
                                    <div className="pdf-docs-wrapper-icon docs-wrapper-icon" style={{ position: "static", border: "1px solid white" }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" viewBox="0 0 16 16">
                                            <path stroke="white" strokeWidth=".3" d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
                                        </svg>
                                    </div>
                                </Tippy>
                            </div>
                        </div>
                        {
                            error ?

                            <div style={{ color: componentStatus === 'RUNNING' ? "var(--gray-six)" : "" }}>
                                {error}
                            </div>

                            :

                            (
                                componentAttempt && componentAttempt > 0 ?
                                    <>
                                        Attempt {componentAttempt}{componentStatus === 'RUNNING' ? " is processing" : ""}:
                                        <div className="true-false-top lambda-results-container">
                                            {
                                                typeof componentResults === "object" && componentResults !== null ? 

                                                Object.keys(componentResults).map((id) => {                            

                                                    if (id in testsMap) {
                                                        const test_result = componentResults[id];
                                                        const original_test = testsMap[id];

                                                        return <ASingleTestLambdaGrading passPoints={original_test.passPoints} greyedOut={componentStatus === 'RUNNING'} key={id} testName={original_test.testName} pointsEarned={original_test[test_result.passed ? "passPoints" : "failPoints"]} output={test_result.output} passed={test_result.passed}/>
                                                    }
                                                })

                                                :
                                                
                                                (
                                                    componentResults ?
                                                    
                                                    <div style={{ color: componentStatus === 'RUNNING' ? "var(--gray-six)" : "" }}>
                                                        {componentResults}
                                                    </div>

                                                    :

                                                    <>
                                                    </>
                                                )
                                            }
                                        </div>
                                    </>
                                :

                                <>
                                </>
                            )
                        }
                    </div>
                : 

                <>
                </>
            }
        </>    
    );
}

export default RunNewTests;