import React, { useState, useEffect } from "react";
import TrashQuestion from "./trashQuestion";
import Grid from "./grid";
import { languageMap } from "./languagemap";
import { languageReadableMap } from "./languageReadableMap";
import useWindowWidth from "./useWindowWidth";
import AddTestsForm from "./addTestsForm";
import TypesOfQuestionsEnum from "./typesOfQuestionsEnum";
import Explanation from "./explanation";
import Tippy from "@tippyjs/react";
import { useNavigate, useLocation } from "react-router-dom";


const CodeResponseEdit = ({ firstTime, ignoredPrefixes, explanation, useExplanation, updateQuestions, questionsToSave, ignoredPaths, testsConfigDict, tests, showDrag, updateQuestionsToSave, provided, useAutocompletion, contents, updateDeleteQuestion, index, points, question, uuid, questionType, location, language, useSyntaxHighlighting, zipFileExists, bankUuid }) => {    
    const width = useWindowWidth(500);
    const navigate = useNavigate();
    const [selectTests, setSelectTests] = useState(false);
    const locationURL = useLocation();
    const [form, setForm] = useState({
        question: question,
        points: Number(points).toFixed(2),
        uuid: uuid,
        questionType: questionType,
        location: location,
        language: language,
        tests: tests,
        useAutocompletion: useAutocompletion,
        useExplanation: useExplanation,
        explanation: explanation
    });
    const [open, setOpen] = useState(false);

    function updateSelectTests(bool) {
        setSelectTests(bool);
    }

    function updateForm(key, value) {
        setForm((prev) => (
            {
                ...prev,
                [key]: value
            }
        ))
    }

    function updateTests(testId) {
        if (testId && !form.tests.includes(testId)) {
            let newTotalPoints = 0;

            for (let i = 0; i < form?.tests?.length; i++) {
                let id = form.tests[i]

                if (id in testsConfigDict && Number.isFinite(testsConfigDict[id].totalPoints)) {
                    newTotalPoints += testsConfigDict[id].totalPoints;
                }
            }

            newTotalPoints += testId in testsConfigDict ? testsConfigDict[testId].totalPoints : 0;

            setForm((prev) => (
                {
                    ...prev,
                    tests: [...form.tests.filter((id) => (id in testsConfigDict)), testId],
                    points: newTotalPoints.toFixed(2).toString()
                }
            ))
        } else if (!testId && !form.tests.includes(testId)) {
            setForm((prev) => (
                {
                    ...prev,
                    tests: [],
                    points: "0.00"
                }
            ))  
        }          
    }

    const submitForm = async () => {
        // construct a new body for post with all values that have changed
        let body = {};
        body.fields = {}

        // check if we have something previous
        if (form.uuid in questionsToSave) {
            body.fields = questionsToSave[form.uuid].fields
        }

        body.uuid = uuid
        body.questionType = questionType        

        // Check each property for changes
        Object.keys(form).forEach(key => {
            if (form[key] !== { points, question, uuid, tests, questionType, language, location, useAutocompletion, useExplanation, explanation }[key]) {
                if (key !== "uuid" || key !== "questionType") {                
                    if (key === "points") {
                        if (form.points !== Number(points).toFixed(2)) {
                            body.fields[key] = form[key];
                        }
                    } else {
                        body.fields[key] = form[key];
                    }
                }
            }
        });

        if (Object.keys(body.fields).length === 0) {
            return;
        }

        // updateQuestionsToSave
        updateQuestionsToSave((prev) => ({
            ...prev,
            [form.uuid]: body
        }))
    };

    useEffect(() => {
        // Define a timeout to submit the form after 0.5s
        const timer = setTimeout(() => {
            submitForm();
        }, 500); // 500ms delay
    
        // Cleanup function to clear the timeout if dependencies change
        return () => {
            clearTimeout(timer);
        };
    }, [form.points, form.question, form.explanation]);

    useEffect(() => {
        if (form.location !== location) {
            const lastSegment = form.location.split('.').pop(); // Gets the string after the last .
            const newLanguage = lastSegment in languageMap ? languageMap[lastSegment] : "plaintext";
            updateForm("language", newLanguage);
            submitForm();
        }
    }, [form.location]);

    useEffect(() => {
        submitForm();
    }, [form.language, form.useAutocompletion, form.tests, form.location, form.useExplanation])

    const handleTestDeletion = (idOfTest) => {
        let newTotalPoints = 0;

        for (let i = 0; i < form?.tests?.length; i++) {
            let id = form.tests[i]

            if (id in testsConfigDict && id !== idOfTest && Number.isFinite(testsConfigDict[id].totalPoints)) {
                newTotalPoints += testsConfigDict[id].totalPoints;
            }
        }

        setForm((prev) => (
            {
                ...prev,
                tests: form.tests.filter((id) => id !== idOfTest && id in testsConfigDict),
                points: newTotalPoints.toFixed(2).toString()
            }
        ))
    }

    useEffect(() => {
        updateQuestions((prev) => 
            prev.map((question) => {
                if (question.questionType === TypesOfQuestionsEnum.BANK_OF_QUESTIONS) {
                    return {
                        ...question,
                        questions: question.questions.map((nestedQuestion) => {
                            if (nestedQuestion.uuid === form.uuid) {
                                return form;
                            }
                            return nestedQuestion;
                        }),
                    };
                } else if (question.uuid === form.uuid) {
                    return form;
                }
                return question;
            })
        );
    }, [form]);

    useEffect(() => {
        try {
            if ((ignoredPaths && ignoredPrefixes && contents && Object.keys(contents).length > 0 && form.location) && (!(form.location in contents) || (form.location in ignoredPaths) || Array.from(ignoredPrefixes).some(prefix => form.location?.startsWith(prefix)))) {
                updateForm("location", "");
                updateForm("language", "plaintext");
                updateForm("tests", []);
            } else if (form.location === "" && form.tests?.length > 0) {
                updateForm("location", "");
                updateForm("tests", []);
            }

        } catch (error) {
            console.log(error);
        }
    }, [form.location, ignoredPaths, ignoredPrefixes])

    const handleClick = (path, event) => {
        if (event.metaKey || event.ctrlKey) {
            // Open in a new tab
            window.open(`/${path}`, '_blank');
        } else {
            // Navigate in the same tab
            navigate(`/${path}`);
        }
    }

    const setNewParams = () => {
        const searchParams = new URLSearchParams(locationURL.search);
        if (searchParams.get('uuid') === uuid) {
            searchParams.set("uuid", ""); 
        } else {
            searchParams.set("uuid", uuid);
        }
        
        navigate(`?${searchParams.toString()}`);
    }
    
    const currentId = new URLSearchParams(locationURL.search).get('uuid');

    useEffect(() => {
        if (firstTime) {
            setOpen(firstTime);
        }

    }, [firstTime])
    
    return (
        <>
            <div className="true-false-question-view" id={uuid}>
                <div className="question-number">
                    <div className="grid-number-container">
                        {
                            showDrag

                            ?

                            <>
                                <Grid provided={provided}/>
                            </>

                            :

                            <>
                            </>

                        }
                        Q{ index }
                        <Tippy content={uuid === currentId ? "Deselect Question" : "Select Question"} placement="right">
                            <input type={"checkbox"} className="check-auto" checked={uuid === currentId} onChange={() => {setNewParams()}} style={{ cursor: "pointer" }}/>
                        </Tippy>
                        <div onClick={(e) => handleClick("autograder-docs", e)}>
                            <Tippy content={"Click to navigate to autograder docs (max size ≈400KB"} placement={"top"} theme="custom-tooltip">
                                <div className="docs-wrapper-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" viewBox="0 0 16 16">
                                        <path stroke="currentColor" strokeWidth=".3" d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
                                    </svg>
                                </div>
                            </Tippy>
                        </div>
                    </div>
                    <div className="pts">
                        <>
                            <input className="grading-box-question" type="none" value={form.points} onChange={(e) => {
                                updateForm("points", e.target.value);
                            }}/>
                            pts
                        </>
                        <TrashQuestion uuid={uuid} updateDeleteQuestion={updateDeleteQuestion} bankUuid={bankUuid}/>
                    </div>
                </div>
                <div className="text-response-area">
                    <textarea className="text-response-area-input" placeholder="Enter question (render Markdown and LaTeX)" style={{ minHeight: "100px" }} value={form.question} onChange={(e) => {
                        updateForm("question", e.target.value);
                    }}/>
                </div>
                <div className="container-zip-location">
                    {
                        zipFileExists

                        ?

                        <>
                            <div className="message-container" style={{ flexDirection: width ? "column" : "" }}>
                                <div className="message-container" style={{ alignItems: "center" }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" style={{ minWidth: "16px" }}>
                                        <path d="M8.5 9.438V8.5h-1v.938a1 1 0 0 1-.03.243l-.4 1.598.93.62.93-.62-.4-1.598a1 1 0 0 1-.03-.243"/>
                                        <path d="M4 0h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2m2.5 8.5v.938l-.4 1.599a1 1 0 0 0 .416 1.074l.93.62a1 1 0 0 0 1.109 0l.93-.62a1 1 0 0 0 .415-1.074l-.4-1.599V8.5a1 1 0 0 0-1-1h-1a1 1 0 0 0-1 1m1-5.5h-1v1h1v1h-1v1h1v1H9V6H8V5h1V4H8V3h1V2H8V1H6.5v1h1z"/>
                                    </svg>
                                    Zip File Location:
                                </div>
                                <select className="metric-select question" value={form.location in contents ? form.location : ""} onChange={(e) => {
                                    updateForm("location", e.target.value)
                                }} style={{ width: "auto", maxWidth: "150px" }}>
                                    <option key={""} value={""}>
                                        None
                                    </option>
                                    {Object.entries(contents)
                                        .filter(([key, value]) => !value['isDir'])
                                        .map(([key, value]) => (
                                            !(ignoredPaths && key in ignoredPaths) && !(ignoredPrefixes && Array.from(ignoredPrefixes).some(prefix => key.startsWith(prefix))                                        )

                                            ?

                                            <option key={key} value={key}>
                                                {key}
                                            </option>

                                            :

                                            null
                                        ))
                                    }                                    
                                </select>
                            </div>    
                        </>

                        :

                        <>
                            <div className="message-container" style={{ flexDirection: width ? "column" : "" }}>
                                <div className="message-container" style={{ alignItems: "center" }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" style={{ minWidth: "16px" }}>
                                        <path d="M8.5 9.438V8.5h-1v.938a1 1 0 0 1-.03.243l-.4 1.598.93.62.93-.62-.4-1.598a1 1 0 0 1-.03-.243"/>
                                        <path d="M4 0h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2m2.5 8.5v.938l-.4 1.599a1 1 0 0 0 .416 1.074l.93.62a1 1 0 0 0 1.109 0l.93-.62a1 1 0 0 0 .415-1.074l-.4-1.599V8.5a1 1 0 0 0-1-1h-1a1 1 0 0 0-1 1m1-5.5h-1v1h1v1h-1v1h1v1H9V6H8V5h1V4H8V3h1V2H8V1H6.5v1h1z"/>
                                    </svg>
                                    Upload a zip file to set a location for autograding
                                </div>                         
                            </div>
                        </>

                    }
                </div>                
                <div className="container-zip-location">
                    {
                        useSyntaxHighlighting

                        ?

                        <>
                            <div className="message-container" style={{ flexDirection: width ? "column" : "", width: "100%"  }}>
                                <div className="message-container" style={{ alignItems: "center" }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" style={{ minWidth: "16px" }}>
                                        <path d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2M6.646 5.646a.5.5 0 1 1 .708.708L5.707 8l1.647 1.646a.5.5 0 0 1-.708.708l-2-2a.5.5 0 0 1 0-.708zm2.708 0 2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L10.293 8 8.646 6.354a.5.5 0 1 1 .708-.708"/>
                                    </svg>
                                    Code Syntax:
                                </div>
                                <select className="metric-select question" value={form.language} onChange={(e) => {
                                    updateForm("language", e.target.value)
                                }} style={{ width: "auto", maxWidth: "150px" }}>
                                    {Object.entries(languageReadableMap).map(([key, value]) => (
                                        <option key={key} value={value}>
                                            { key }
                                        </option>
                                    ))}
                                </select>
                            </div>    
                        </>                        

                        :

                        <>
                            <div className="message-container">                                
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" style={{ minWidth: "16px" }}>
                                    <path d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2M6.646 5.646a.5.5 0 1 1 .708.708L5.707 8l1.647 1.646a.5.5 0 0 1-.708.708l-2-2a.5.5 0 0 1 0-.708zm2.708 0 2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L10.293 8 8.646 6.354a.5.5 0 1 1 .708-.708"/>
                                </svg>
                                Turn on syntax highlighting to configure question-level syntax
                            </div>
                        </>
                    }
                </div>
                <div className="container-zip-location">
                    {
                        useSyntaxHighlighting

                        ?

                        <>
                            <div className="message-container" style={{ alignItems: "center" }}>
                                <div className="message-container" style={{ alignItems: "center" }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" style={{ minWidth: "16px" }}>
                                        <path d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2m-1.146 6.854-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 8.793l2.646-2.647a.5.5 0 0 1 .708.708"/>
                                    </svg>                                    
                                    Code Autocompletion:
                                </div>
                                <input className="check-auto" type="checkbox" checked={form.useAutocompletion} onChange={(e) => updateForm("useAutocompletion", e.target.checked)} />
                            </div>    
                        </>                        

                        :

                        <>
                            <div className="message-container">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" style={{ minWidth: "16px" }}>
                                    <path d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2m-1.146 6.854-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 8.793l2.646-2.647a.5.5 0 0 1 .708.708"/>
                                </svg> 
                                Turn on syntax highlighting to use code autocompletion
                            </div>
                        </>
                    }                    
                </div>
                <div className="container-zip-location">
                    {
                        zipFileExists

                        ?

                        form.location in contents
                        
                        ?

                        <>
                            <div className="container-for-test-config">
                                <div className="message-container" style={{ flexDirection: width ? "column" : "" }}>
                                    <div className="message-container" style={{ alignItems: "center" }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" style={{ minWidth: "16px" }}>
                                            <path d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2M6 5.883a.5.5 0 0 1 .757-.429l3.528 2.117a.5.5 0 0 1 0 .858l-3.528 2.117a.5.5 0 0 1-.757-.43V5.884z"/>
                                        </svg>
                                    </div>    
                                    <div className="metric-select question-2" onClick={() => {
                                        setOpen(prev => !prev)                                       
                                    }}>
                                        Tests
                                        <div className="center-chevron">
                                            {
                                                open ?

                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                                    <path d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"/>
                                                </svg>

                                                :

                                                
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                                    <path d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
                                                </svg>

                                            }
                                        </div>
                                    </div>
                                    <div className="tests-button" onClick={() => updateSelectTests(true)}>
                                        Select Tests
                                    </div>
                                </div>
                                {
                                    form && open && form?.tests?.length > 0

                                    ?
                                
                                    <div className="selected-tests">
                                        {
                                            form.tests.filter((id) => (id in testsConfigDict)).map((idOfTest, index) => (
                                                <div key={idOfTest} className="selected-test">                                                   
                                                    <svg onClick={() => handleTestDeletion(idOfTest)} style={{ cursor: "pointer", minWidth: "16px" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                                        <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5"/>
                                                    </svg> 
                                                    {index + 1}: {testsConfigDict[idOfTest].name}
                                                </div>
                                            ))
                                        }
                                    </div>

                                    :

                                    <>
                                    </>

                                }
                            </div>                             
                        </>

                        :

                        <>
                            <div className="message-container code-response">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" style={{ minWidth: "16px" }}>
                                    <path d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2M6 5.883a.5.5 0 0 1 .757-.429l3.528 2.117a.5.5 0 0 1 0 .858l-3.528 2.117a.5.5 0 0 1-.757-.43V5.884z"/>
                                </svg>
                                Choose a valid zip file location to select tests for autograding
                            </div>                            
                        </>

                        :

                        <>
                            <div className="message-container">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" style={{ minWidth: "16px" }}>
                                    <path d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2M6 5.883a.5.5 0 0 1 .757-.429l3.528 2.117a.5.5 0 0 1 0 .858l-3.528 2.117a.5.5 0 0 1-.757-.43V5.884z"/>
                                </svg>
                                Upload a zip file to select tests for autograding
                            </div>
                        </>

                    }
                </div>
                <div className="select-all-that-apply-array">
                    <Explanation form={form} updateForm={updateForm} submitForm={submitForm}/>
                </div>
            </div>
            { selectTests ? <AddTestsForm testsConfigDict={testsConfigDict} updateTests={updateTests} updateSelectTests={updateSelectTests} handleTestDeletion={handleTestDeletion} tests={form.tests}/> : <></> }
        </>
    );
}

export default CodeResponseEdit;