import React, { useState, useEffect } from "react";
import TypesOfLocationEnum from "./typesOfLocationEnum";
import WhiteLoading from "./whiteLoading";
import ASingleTestGrading from "./aSingleTestGrading";
import SelectZipFile from "./selectZipFile";
import { useParams } from "react-router-dom";
import uploadFileToS3 from "./uploadFileToS3";
import validateZipFile from "./validateZipFile";
import Tippy from "@tippyjs/react";
import ReloadButton from "./reloadButton";
import InitSortQuestions from "./initSortQuestions";
import findTestsFromQsAndBankedQs from "./findTestsFromQsAndBankedQs";
import useWindowWidth from "./useWindowWidth";

const UploadAndViewTests = ({ updateQuestionsForUpload, updateTestsConfigDictForUpload, updateStatus, status, updateAttempt, attempt, autograderRequestLifeCycles, updateAutograderRequestLifeCycles, error, updateError, testsConfigDictForUpload, questionsForUpload, initQuestions, updateCurrentViewer }) => {
    const [inputZipFile, setInputZipFile] = useState("");
    const [text, setText] = useState(initQuestions && initQuestions.length > 0 ? "Upload a test zip file below" : "No questions to test at this time");
    const { courseCode, assessmentCode } = useParams();  
    const [showResults, setShowResults] = useState(false);  
    const width = useWindowWidth(500);

    const updateZipFile = async (file) => {
        const [isValid, message] = await validateZipFile(file);
        
        if (!isValid) {
            setText(message);
            return;
        }

        setInputZipFile(file)
    }

    const uploadZipBeforeAutograde = async () => {
        try {
            const response = await fetch( process.env.REACT_APP_SUBMISSION_API_URL + `/get-url-test-zip/${courseCode}/${assessmentCode}`, { method: "GET", credentials: "include" })
            const data = await response.json()

            if (response.status === 200 && data.url) {
                const status = await uploadFileToS3(inputZipFile, data.url, "application/zip");

                if (status !== 200) {
                    setText("Zip failed to upload");
                }

                return 200

            } else if (response.status === 401) {
                window.location.href = process.env.REACT_APP_SUBMISSION_API_URL;
            } else {
                setText("Could not fetch signed url")
                return 400
            }
        } catch (error) {
            console.log(error)
            setText(error.message)            
            return 400
        }
    }

    useEffect(() => {
        const uploadAndTestZipFile = async () => {
            if (autograderRequestLifeCycles) {
                return;
            }
            
            // max size
            const maxSizeBytes = 5 * 1024 * 1024;

            // form validation
            if (inputZipFile.size > maxSizeBytes) {
                setText("Zip File exceeds 5MB.")
                return;
            }

            try {
                updateAutograderRequestLifeCycles(true)
                const statusCode = await uploadZipBeforeAutograde();

                if (statusCode !== 200) {
                    return;
                }

                if (!inputZipFile) {
                    return;             
                }

                const url = process.env.REACT_APP_START_AUTOGRADER_API_URL
                const urlOptions = {
                    method: "POST",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        courseCode: courseCode,
                        assessmentCode: assessmentCode,
                        email: "test"
                    })
                }

                const response = await fetch(url, urlOptions);
                const data = await response.json();
                if (response.status === 200) {
                    updateAttempt(attempt + 1);
                    updateStatus('RUNNING');
                } else if (response.status === 401) {
                    window.location.href = process.env.REACT_APP_401_REDIRECT_URL
                } else {
                    updateAttempt(-1);
                    updateError(data.detail);
                    updateQuestionsForUpload([]);
                    updateTestsConfigDictForUpload({});
                }

            } catch (error) {
                updateAttempt(-1);
                updateError(error.message);            
                updateQuestionsForUpload([]);
                updateTestsConfigDictForUpload({});
                console.log(error);
            } finally {
                updateAutograderRequestLifeCycles(false);      
                setShowResults(true);
            }
        }

        if (inputZipFile) {
            uploadAndTestZipFile();
        }

    }, [inputZipFile])

    useEffect(() => {
        setText(initQuestions && initQuestions.length > 0 ? "Upload a test zip file below" : "No questions to test at this time");
    }, [initQuestions])

    const findMessage = () => {
        if (status === "RUNNING") {
            return (
                <>
                    Test New Zip (Attempt {attempt} is processing)
                </>
            );
        }
    
        return (
            <>
                Test New Zip ({attempt !== -1 ? `Attempt ${attempt}` : "Request Error" })
            </>
        );
    }

    const fetchResults = async () => {
        if (autograderRequestLifeCycles) {
            return;
        }

        try {
            updateAutograderRequestLifeCycles(true);
            updateError(null);
            updateTestsConfigDictForUpload({});
            const url = process.env.REACT_APP_SUBMISSION_API_URL + `/get-tests/${courseCode}/${assessmentCode}`
            const urlOptions = {
                method: "GET",
                credentials: "include",                
            }
            
            const response = await fetch(url, urlOptions);
            const data = await response.json();
            if (response.status === 200) {
                if (data.OK) {
                    updateTestsConfigDictForUpload(data.testsConfigDict)
                    const gradedQuestions = InitSortQuestions(data.questionsOrder, data.gradedQuestions);

                    const tests = findTestsFromQsAndBankedQs(gradedQuestions)

                    if (tests.length === 0) {
                        updateError("No saved questions available with selected tests.")
                    } else {
                        updateError(null);
                    }

                    updateQuestionsForUpload(tests);
                } else {
                    updateError(data.error);
                    updateQuestionsForUpload([]);
                    updateTestsConfigDictForUpload({});
                }
                updateAttempt(data.attempt)
                updateStatus(data.status);
            } else if (response.status === 401) {
                window.location.href = process.env.REACT_APP_401_REDIRECT_URL
            } else {                
                updateError(data.detail);
                updateStatus('COMPLETED');
                updateQuestionsForUpload([]);
                updateTestsConfigDictForUpload({});
            }

        } catch (error) {
            console.log(error);
            updateError(error.message);
            updateStatus('COMPLETED');
            updateQuestionsForUpload([]);
            updateTestsConfigDictForUpload({});
        } finally {
            updateAutograderRequestLifeCycles(false);            
        }
    }

    return (
        <>
            {
                autograderRequestLifeCycles

                ?

                <div className="loading-zip-container">
                    <WhiteLoading />
                </div>

                :

                <>
                    {
                        showResults

                        ?

                        <>
                            <div className="question-tests-container">
                                <div className="clear-container" style={{ columnGap: "10px" }}>                                   
                                    <div className="top-right-item" onClick={() => {
                                        setShowResults(false);
                                    }} style={{ paddingTop: "10px", paddingBottom: "10px", height: "fit-content", width: "auto", paddingLeft: "30px", paddingRight: "30px" }}>
                                        {findMessage()}
                                    </div>
                                </div>
                                {
                                    questionsForUpload.map((question) => (
                                        question.tests.map((id) => {
                                            if (id in testsConfigDictForUpload) {
                                                return <ASingleTestGrading greyedOut={status === "RUNNING"} instructorPage={true} key={id} {...testsConfigDictForUpload[id]} index={question.index}/>
                                            }
                                        })
                                    ))
                                }
                                {
                                    error && (
                                        <div style={{ color: status === "RUNNING" ? "var(--gray-six)" : "" }}>
                                            {error}
                                        </div>
                                    )
                                }
                            </div>
                            <div onClick={fetchResults} className="absolute-reload" style={{ right: width ? "60px" : "" }}>
                                <ReloadButton />
                            </div>
                        </>

                        :

                        <>
                            <div className="no-questions">
                                <div className="no-assignments-container" style={{ paddingTop: "0px"}}>
                                    <div className="no-assignments">
                                        { text }
                                        {
                                            initQuestions && initQuestions.length > 0 ?

                                            <div className="input-wrapper-test-zip" style={{ position: "relative" }}>
                                                <div style={{ width: "80%", height: "27.5px" }}>
                                                    <SelectZipFile star={false} value={inputZipFile} updateValue={updateZipFile} required={true}/>
                                                </div>
                                                <Tippy placement="bottom" content="View results for saved tests that are mapped to questions" theme="custom-tooltip">
                                                    <div className="pdf-docs-wrapper-icon docs-wrapper-icon" style={{ position: "static" }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" viewBox="0 0 16 16">
                                                            <path stroke="currentColor" strokeWidth=".3" d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
                                                        </svg>
                                                    </div>
                                                </Tippy>
                                            </div>

                                            :

                                            <>
                                                <div className="save-changes-2" onClick={() => {
                                                    updateCurrentViewer(TypesOfLocationEnum.CUSTOM_EXAM);
                                                }}>
                                                    Back To Questions
                                                </div>
                                            </>

                                        }
                                        {
                                            attempt > 0 ?

                                            <div className="show-prev" onClick={() => {
                                                setShowResults(true);
                                            }}>
                                                Show previous results
                                            </div>

                                            :

                                            <>
                                            </>

                                        }
                                    </div>
                                </div>
                            </div>
                        </>

                    }
                </>

            }
        </>
    );
}

export default UploadAndViewTests;