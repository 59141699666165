import React from "react";
import Instructor from "./instructor";
import Tippy from "@tippyjs/react";

const InstructorInfo = (props) => {
    return (
        <>
            <Tippy placement="right" content="Remove Instructor">
                <div className="instructor-name" style={{ cursor: "pointer" }} onClick={() => {
                    props.updateInstructor(props.instructor, props.email);
                    props.updateRemove(true);
                }}>
                    <Instructor /> { props.instructor }
                </div>
            </Tippy>
        </>
    );
}

export default InstructorInfo;