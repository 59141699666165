import React, { useState } from "react";
import useWindowWidth from "./useWindowWidth";
import intToLetters from "./intToLetters";
import formatTime from "./formatTime";
import FeedbackWindow from "./feedbackWindow";
import GradedPercentWrapper from "./gradedPercentWrapper";
import Loading from "./loading";
import GradingPageCustomExamWrapper from "./gradingPageCustomExamWrapper";
import OnlyIInfo from "./onlyIInfo";
import Tippy from "@tippyjs/react";
import TypesOfQuestionsEnum from "./typesOfQuestionsEnum";
import { useParams } from "react-router-dom";

const LeftInfoGradingPage = ({ findIfLambdaExists, text, updateText, updateAssessmentInformation, updateStatus, updateSetStillProcessing, status, stillProcessing, endedExam, error, testsConfigDict, id, updateId, ignoredPaths, ignoredPrefixes, requestLifeCycleTwo, updatePdfUrls, pdfUrls, countsInformation, isDarkTheme, updateIsDarkTheme, contents, updateCurrentViewer, requestLifeCycle, handleBlur, handleFocus, assessmentInformation }) => {
    const width = useWindowWidth(1000);    
    const [requestLifeCycleLocal, setRequestLifeCycleLocal] = useState(false);
    const { courseCode, assessmentCode } = useParams();        
    
    const reRunAutograders = async () => {
        try {
            setRequestLifeCycleLocal(true);

            const url = process.env.REACT_APP_START_AUTOGRADER_API_URL
            const urlOptions = {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    courseCode: courseCode,
                    assessmentCode: assessmentCode,
                    email: assessmentInformation.email
                })
            }

            const response = await fetch(url, urlOptions);
            if (response.status === 200) {
                updateSetStillProcessing(true);
                updateStatus('RUNNING');
                updateAssessmentInformation((prev) => {
                    return {
                        ...prev,
                        gradedQuestions: [...prev.gradedQuestions].map((question) => {
                            if (question.questionType === TypesOfQuestionsEnum.LAMBDA) {
                                return {
                                    ...question,
                                    status: "RUNNING"
                                }
                            }

                            return {
                                ...question
                            }
                        })
                    }
                })
                updateText("Success");
            } else if (response.status === 401) {
                window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
            } else {
                updateText("Failed");
            }

        } catch (error) {
            updateText("Failed");
            console.log(error)
        } finally {
            setRequestLifeCycleLocal(false);
        }
    }    

    return (
        <>
            <div className={`left-info fullscreen ${width ? "small" : ""}`} style={{ paddingBottom: "0px" }}>
                <div className="left-info-content" style={{ border: "none" }}>
                    {
                        requestLifeCycle

                        ?

                        <>
                            <div className="loading-container">
                                <Loading />
                            </div>  
                        </>

                        :

                        <>
                            {
                                assessmentInformation && Object.keys(assessmentInformation).length > 0

                                ?

                                <>
                                    <div className="side-nav-info-header" style={{ display: "flex", justifyContent: "space-between", columnGap: "10px", width: "100%" }}>
                                        <span className="student-name-overflow">
                                            { assessmentInformation.name }
                                        </span>
                                    </div>
                                    <div className="side-nav-info-header">
                                        Score
                                    </div>
                                    <div className="text-side-nav display-2">
                                        <div className="points">
                                            { Number(assessmentInformation.pointsEarned).toFixed(2) + " "} / { Number(assessmentInformation.points).toFixed(2) }   
                                            {
                                                stillProcessing && (
                                                    <Tippy content={<span style={{ fontSize: '12px' }}>Score processing: latest changes may not be reflected.</span>} placement={"right"}>
                                                        <div style={{ cursor: "pointer" }}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#ff3f3f" viewBox="0 0 16 16">
                                                                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5m.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2"/>
                                                            </svg>
                                                        </div>
                                                    </Tippy>
                                                )
                                            }
                                            {
                                                requestLifeCycleLocal ? 

                                                <div style={{ height: "30px", display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                                                    <Loading />
                                                </div>
                                                
                                                :

                                                (assessmentInformation.zipFileExists || findIfLambdaExists()) && (
                                                    <Tippy content={<span style={{ fontSize: '12px' }}>{text}</span>} placement={"right"}>
                                                        <div className="re-run-autograder" style={{ backgroundColor: text === "Success" ? "var(--correct)" : ( text === "Failed" ? "var(--incorrect)" : "") }} onClick={reRunAutograders}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                                                <path d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393"/>
                                                            </svg>
                                                        </div>
                                                    </Tippy>                                                    
                                                )
                                            }                                        
                                        </div>                                     
                                    </div>                                    
                                    {
                                        assessmentInformation.formIds.length > 1 

                                        ?

                                        <>
                                            <div className="side-nav-info-header">
                                                Form Id
                                            </div>
                                            <div className="text-side-nav display-2">
                                                {
                                                    assessmentInformation.formIds.includes(assessmentInformation.formId)
                                                    
                                                    ?
                                                    
                                                    intToLetters(assessmentInformation.formIds.indexOf(assessmentInformation.formId))
                                                    
                                                    :

                                                    "N/A"
                                                }
                                            </div>
                                        </>

                                        :

                                        <>
                                        </>
                                    }
                                    {
                                        'completionTime' in assessmentInformation

                                        ?

                                        <>
                                            <div className="side-nav-info-header">
                                                Completion Time
                                            </div>
                                            <div className="text-side-nav display-2">
                                                { formatTime(assessmentInformation.completionTime) }
                                            </div>
                                        </>
                                        

                                        :

                                        <>
                                        </>
                                    }                                      
                                    <div className="side-nav-info-header" style={{ display: "flex", justifyContent: "space-between", columnGap: "10px", width: "100%" }}>
                                        Feedback
                                    </div>
                                    <FeedbackWindow handleFocus={handleFocus} handleBlur={handleBlur} feedbackCode={assessmentInformation.feedbackCode} feedback={assessmentInformation.feedback}/>
                                    <GradedPercentWrapper countsInformation={countsInformation}/>
                                </>

                                :

                                <>
                                </>

                            }   
                        </>                                            

                    }
                </div>
                <OnlyIInfo />
            </div>
            <GradingPageCustomExamWrapper status={status} endedExam={endedExam} error={error} testsConfigDict={testsConfigDict} id={id} updateId={updateId} ignoredPaths={ignoredPaths} ignoredPrefixes={ignoredPrefixes} requestLifeCycleTwo={requestLifeCycleTwo} requestLifeCycle={requestLifeCycle} updatePdfUrls={updatePdfUrls} pdfUrls={pdfUrls} isDarkTheme={isDarkTheme} updateIsDarkTheme={updateIsDarkTheme} updateCurrentViewer={updateCurrentViewer} contents={contents} gradedQuestions={assessmentInformation.gradedQuestions}/>
        </>
    );
}

export default LeftInfoGradingPage;