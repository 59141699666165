import React, { useEffect, useState } from "react";
import useWindowWidth from "./useWindowWidth";
import { useParams, useNavigate } from "react-router-dom";
import convertDate from "./convertDate";
import CustomSwitch from "./customSwitch";
import Regenerate from "./regenerate";
import Trash from "./trash";
import intToLetters from "./intToLetters";
import Loading from "./loading";
import handleStatusDate from "./handleStatusDate";
import HandleStatus from "./handleStatus";
import EditAssessmentForm from "./editAssessmentForm";
import Tippy from "@tippyjs/react";
import TypesOfLocationEnum from "./typesOfLocationEnum";

const LeftInfoSettings = ({ requestLifeCycle, assessmentInformation, updateAssessmentInformationParent, updateDeleteForm    , updateDeleteZip }) => {
    const width = useWindowWidth(850);
    const navigate = useNavigate();
    const { courseCode, assessmentCode } = useParams();    
    const [initAttemptsCall, setInitAttemptsCall] = useState(true);
    const [initAttemptsPCall, setInitAttemptsPCall] = useState(true);
    
    const submitForm = async () => {       
        if (initAttemptsCall) {
            setInitAttemptsCall(false);
            return;
        }

        const url = process.env.REACT_APP_EVALUATION_API_URL + "/update-attempts";

        const urlOptions = {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                courseCode: courseCode,
                assessmentCode: assessmentCode,
                attempts: assessmentInformation.attempts
            })
        };
        
        try {
            const res = await fetch(url, urlOptions);
            const data = await res.json();
            if (res.status === 200) {
                updateAssessmentInformation("attempts", data.attempts)
            } else if (res.status === 401) {
                window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
            }
        } catch (error) {
            console.log(error);
        }
    }
    
    const submitFormP = async () => {   
        if (initAttemptsPCall) {
            setInitAttemptsPCall(false);
            return;
        }

        const url = process.env.REACT_APP_EVALUATION_API_URL + "/update-attempts-programming";

        const urlOptions = {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                courseCode: courseCode,
                assessmentCode: assessmentCode,
                attempts: assessmentInformation.allowedAttemptsProgramming
            })
        };
        
        try {
            const res = await fetch(url, urlOptions);
            const data = await res.json();
            if (res.status === 200) {
                updateAssessmentInformation("allowedAttemptsProgramming", data.attempts)
            } else if (res.status === 401) {
                window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            submitForm();
        }, 500)

        return () => {
            clearTimeout(timer)
        }
        
    }, [assessmentInformation.attempts])

    useEffect(() => {
        const timer = setTimeout(() => {
            submitFormP();
        }, 500)

        return () => {
            clearTimeout(timer)
        }
        
    }, [assessmentInformation.allowedAttemptsProgramming])

    function updateAssessmentInformation(type, value, switchClick = false) {
        updateAssessmentInformationParent((prev) => ({
            ...prev,
            [type]: value
        }))

        if (switchClick) {
            handleSwitchClick(value, type);
        }
    }

    const handleCodeClick = async () => {
        const url = process.env.REACT_APP_EVALUATION_API_URL + `/regenerate-entry-code`

        const urlOptions = {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                courseCode: courseCode,
                entryCode: assessmentInformation.entryCode,
                assessmentCode: assessmentCode
            })
        }

        try {
            const res = await fetch(url, urlOptions);
            const data = await res.json();
            if (res.status === 200 && data.entryCode) {
                updateAssessmentInformation("entryCode", data.entryCode)
            }
            else if (res.status === 401) {
                window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
            }
    
        } catch (error) {
            console.log(error);
        }
    }

    const handleSwitchClick = async (boolVal, updateVal) => {
        const url = process.env.REACT_APP_EVALUATION_API_URL + "/evaluation-item-toggle";

        const urlOptions = {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                boolVal: boolVal,
                updateVal: updateVal,
                courseCode: courseCode,
                start: assessmentInformation.start,
                end: assessmentInformation.end,
                assessmentCode: assessmentCode,
                isProgramming: assessmentInformation.isProgramming,
                duration: assessmentInformation.duration.toString(),
                metric: assessmentInformation.metric
            })
        };

        try {
            const res = await fetch(url, urlOptions);
            if (res.status === 401) {
                window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
            }
    
        } catch (error) {
            console.log(error);
        }
    }

    const handleFormClick = (event, formIndex) => {
        const queryParams = new URLSearchParams({
            formIndex
        }).toString();

        if (event.metaKey || event.ctrlKey) {
            // Open in a new tab
            window.open(`/${courseCode}/${assessmentCode}/edit?${queryParams}`, '_blank');
        } else {
            // Navigate in the same tab
            navigate(`/${courseCode}/${assessmentCode}/edit?${queryParams}`);
        }
    }

    const handleZipClick = (event) => {
        const tab = TypesOfLocationEnum.ZIP

        const queryParams = new URLSearchParams({
            tab
        }).toString();

        if (event.metaKey || event.ctrlKey) {
            // Open in a new tab
            window.open(`/${courseCode}/${assessmentCode}/edit?${queryParams}`, '_blank');
        } else {
            // Navigate in the same tab
            navigate(`/${courseCode}/${assessmentCode}/edit?${queryParams}`);
        }
    }

    const status = handleStatusDate(assessmentInformation.start, assessmentInformation.end, assessmentInformation.submissionsLength);

    return (
        <>
            <div className="middle-content" style={{ borderLeft: width ? "none" : "", borderRight: width ? "none" : "" }}>
                {
                    requestLifeCycle

                    ?

                    <>
                        <div className="loading-container">
                            <Loading />
                        </div> 
                    </>

                    :

                    <>
                        {
                            assessmentInformation && Object.keys(assessmentInformation).length > 0

                            ?

                            <>
                                <div className="side-nav-info-header" style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                    <span className="assessment-name">
                                        {assessmentInformation.name}
                                    </span>
                                </div>
                                <div className="side-nav-info-header">
                                    Status
                                </div>
                                <div className="text-side-nav display-2">
                                    { HandleStatus(status) } { status === "Submitted" ? "Closed" : (status !== "Some-Submitted" ? status : assessmentInformation.submissionsLength) }
                                </div>
                                <div className="side-nav-info-header">
                                    Schedule
                                </div>
                                <div className="text-side-nav display">
                                    <div> { "Duration: " + (assessmentInformation.duration === -1 ? "Full-time" : assessmentInformation.duration + " " + assessmentInformation.metric) } </div>
                                    <div> { "Available From: " + convertDate(assessmentInformation.start, false) } </div>
                                    <div> { "Available Until: " + convertDate(assessmentInformation.end, false) } </div>
                                </div>
                                {
                                    assessmentInformation.formIds.length > 0 

                                    ?

                                    <>
                                        <div className="side-nav-info-header" style={{ display: "flex", justifyContent: "space-between", columnGap: "10px", width: "100%", alignItems: "center" }}>
                                            PDF Forms
                                            <Trash updateDeleteForm={updateDeleteForm}/>
                                        </div>
                                    </>

                                    :

                                    <>
                                    </>

                                }
                                <div className="text-side-nav display" style={{ marginTop: "-14px" }}>
                                    {
                                        assessmentInformation.formIds.map((formId, index) => {
                                            return <div key={formId} className="form-id" onClick={(e) => {
                                                handleFormClick(e, index);
                                            }}> Form { intToLetters(index) } </div>
                                        })
                                    }
                                </div>
                                <div className="side-nav-info-header" style={{ display: "flex", justifyContent: "space-between", columnGap: "10px", width: "100%" }}>
                                    Attempts
                                </div>
                                <div className="text-side-nav display">
                                    <div className="codes-container" style={{ justifyContent: "flex-start", columnGap: "10px" }}>
                                        {
                                            assessmentInformation.attempts !== -1
                                            
                                            ?

                                            <input type="number" className="grade-box-enter entry-exit" value={assessmentInformation.attempts} onChange={(e) => {
                                                updateAssessmentInformation("attempts", e.target.value, false);
                                            }} onBlur={submitForm}/>

                                            :
                                            
                                            <>
                                            </>
                                        }
                                        <input type="checkbox" checked={assessmentInformation.attempts === -1} onChange={() => {
                                            if (assessmentInformation.attempts === -1) {
                                                updateAssessmentInformation("attempts", 1, false);  
                                            } else {
                                                updateAssessmentInformation("attempts", -1, false);  
                                            }                                                           
                                        }} onBlur={submitForm}/>
                                        Unlimited                                                                    
                                    </div>
                                </div>
                                {
                                    assessmentInformation.isProgramming && assessmentInformation.zipFileExists

                                    ?

                                    <>
                                        <div className="side-nav-info-header" style={{ display: "flex", justifyContent: "space-between", columnGap: "10px", width: "100%", alignItems: "center" }}>
                                            Zip File
                                            <Trash updateDeleteForm={updateDeleteZip}/>
                                        </div>
                                        {
                                            assessmentInformation?.zipFileExists && assessmentInformation.zipFilename && (
                                                <div className="text-side-nav display" style={{ marginTop: "-10px" }} onClick={handleZipClick}>
                                                    <div className="form-id">
                                                        { assessmentInformation.zipFilename }
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </>

                                    :

                                    <>
                                    </>
                                }
                                <div className="side-nav-info-header" style={{ display: "flex", justifyContent: "space-between", columnGap: "10px", width: "100%" }}>
                                    Entry Code 
                                    <CustomSwitch updateChecked={(e) => {updateAssessmentInformation("useEntryCode", e.target.checked, true)}} checked={assessmentInformation.useEntryCode}/>
                                </div>
                                {
                                    assessmentInformation.useEntryCode

                                    ?

                                        <div className="text-side-nav display">
                                            <div className="codes-container" style={{ justifyContent: "flex-start", columnGap: "10px" }}>
                                                <div type="number" className="grade-box-enter entry-exit">
                                                    {assessmentInformation.entryCode}
                                                </div>
                                                <div onClick={() => handleCodeClick()}>
                                                    <Regenerate />
                                                </div>
                                            </div>
                                        </div>

                                    :

                                    <>
                                    </>

                                }
                                {
                                    false && (
                                        <div className="side-nav-info-header" style={{ display: "flex", justifyContent: "space-between", columnGap: "10px", width: "100%" }}>
                                            <div className="container-for-tippy-lock">
                                                Lockdown Environment
                                                <Tippy content={"Lockdown evals can only be taken in desktop app"} placement={"bottom"} theme="custom-tooltip">
                                                    <div className="docs-wrapper-icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" viewBox="0 0 16 16">
                                                            <path stroke="currentColor" strokeWidth=".3" d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
                                                        </svg>
                                                    </div>
                                                </Tippy>
                                            </div>                                                                    
                                            <CustomSwitch updateChecked={(e) => {updateAssessmentInformation("useLockdownEnvironment", e.target.checked, true)}} checked={assessmentInformation.useLockdownEnvironment}/>                            
                                        </div>
                                    )
                                }                                
                                {
                                    assessmentInformation.isProgramming

                                    ?

                                    <>
                                        <div className="side-nav-info-header" style={{ display: "flex", justifyContent: "space-between", columnGap: "10px", width: "100%" }}>
                                            Syntax Highlighting
                                            <CustomSwitch updateChecked={(e) => {updateAssessmentInformation("useSyntaxHighlighting", e.target.checked, true)}} checked={assessmentInformation.useSyntaxHighlighting}/>
                                        </div>
                                    </>

                                    :

                                    <>
                                    </>
                                }
                                <div className="side-nav-info-header" style={{ display: "flex", justifyContent: "space-between", columnGap: "10px", width: "100%", marginBottom: !assessmentInformation.zipFileExists ? "45px" : "" }}>
                                    Published Submissions
                                    <CustomSwitch updateChecked={(e) => {updateAssessmentInformation("published", e.target.checked, true)}} checked={assessmentInformation.published}/>
                                </div>
                                {
                                    assessmentInformation.zipFileExists && (
                                        <>                                        
                                            <div className="side-nav-info-header" style={{ display: "flex", justifyContent: "space-between", columnGap: "10px", width: "100%"}}>
                                                <div className="container-for-tippy-lock">
                                                    Additional Programming Attempts
                                                    <Tippy content={"Let students test code zip file during evaluation (max 10)"} placement={"bottom"} theme="custom-tooltip">
                                                        <div className="docs-wrapper-icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" viewBox="0 0 16 16">
                                                                <path stroke="currentColor" strokeWidth=".3" d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
                                                            </svg>
                                                        </div>
                                                    </Tippy>
                                                </div>
                                            </div>
                                            <div className="text-side-nav display" style={{ marginBottom: "45px" }}>
                                                <div className="codes-container" style={{ justifyContent: "flex-start", columnGap: "10px" }}>
                                                    {
                                                        assessmentInformation.allowedAttemptsProgramming !== -1 || true
                                                        
                                                        ?

                                                        <input type="number" className="grade-box-enter entry-exit" value={assessmentInformation.allowedAttemptsProgramming} onChange={(e) => {
                                                            updateAssessmentInformation("allowedAttemptsProgramming", e.target.value, false);
                                                        }} onBlur={submitFormP}/>

                                                        :
                                                        
                                                        <>
                                                        </>
                                                    }
                                                    {
                                                        false && (
                                                            <>
                                                                <input type="checkbox" checked={assessmentInformation.allowedAttemptsProgramming === -1} onChange={() => {
                                                                    if (assessmentInformation.allowedAttemptsProgramming === -1) {
                                                                        updateAssessmentInformation("allowedAttemptsProgramming", 1, false);  
                                                                    } else {
                                                                        updateAssessmentInformation("allowedAttemptsProgramming", -1, false);  
                                                                    }                                                           
                                                                }} onBlur={submitFormP}/>
                                                                Unlimited
                                                            </>                                                            
                                                        )
                                                    }                                                    
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                                <EditAssessmentForm isProgramming={assessmentInformation.isProgramming} metric={assessmentInformation.metric} duration={assessmentInformation.duration} name={assessmentInformation.name} start={assessmentInformation.start} end={assessmentInformation.end} viewing={assessmentInformation.viewing} points={assessmentInformation.points}/>
                            </>

                            :

                            <>
                            </>
                        }
                    </>

                }
            </div>
        </>
    );
}

export default LeftInfoSettings;